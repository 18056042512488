body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}
html {
  background-color: #f8f8ff;
}
* {
  font-family: "Tajawal", sans-serif !important;
}

code {
  font-family: "Tajawal", sans-serif;
}
